import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  GET_LIST,
  GET_ONE,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useMutation,
  useNotify,
  usePermissions,
  useRedirect,
  useRefresh,
  useTranslate,
  useUnselectAll,
} from "react-admin";
import get from "lodash/get";
import filter from "lodash/filter";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch } from "react-redux";
import PrintJobModelsTable from "./PrintJobModelsTable";
import config from "../../config/index";
import resources from "../../config/resources";
import fetchService from "../../lib/fetchService";
import { getKey } from "../../lib/i18nUtils";
import ContractorTypes from "../../config/ContractorTypes";
import { HAL_LINK } from "../../dataproviders/v2BackendDataProvider";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadIcon from "@material-ui/icons/Publish";
import { isEmpty } from "lodash/lang";
import { checkPrintjobTimes } from "../../redux/printJob/action";
import CustomDurationInputs from "./CustomDurationInputs";
import CustomDateInputs from "./CustomDateInputs";
import { sortBy } from "lodash/collection";

const useStyles = makeStyles(theme => ({
  form: { padding: theme.spacing(2) },
  printJobInput: {
    width: "100%",
  },
  printDataWrapper: {
    width: "100%",
  },
  autoComplete: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fileWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  inputFile: {
    display: "none",
  },
  horizontalInputsContainer: {
    width: "100%",
    display: "flex",
    gap: `${theme.spacing()}px`,
  },
}));

const validateRequired = value => (value ? undefined : true);

const fetchPrintJobModelsByPrintJob = async (dataProvider, printJobModelsLink) => {
  const response = await dataProvider(HAL_LINK, resources.PRINT_JOB_MODELS, {
    link: printJobModelsLink,
  });
  return Promise.all(
    response.map(async it => {
      const relatedModel = get(await dataProvider(HAL_LINK, resources.MODELS, { link: get(it, "model_S") }), "[0]");
      const relatedJob = get(await dataProvider(HAL_LINK, resources.JOBS, { link: get(relatedModel, "job_S") }), "[0]");
      return {
        ...it,
        modelId: relatedModel?.__id,
        rapidshopId: relatedModel?.rapidshopId,
        externalId: relatedJob?.externalId,
      };
    })
  );
};

const CustomFilteredSelectInput = ({ filter, choices, ...props }) => (
  <SelectInput choices={typeof filter === "function" ? filter(choices) : choices} {...props} />
);

const PrintJobForm = ({ ...props }) => {
  const classes = useStyles();
  const [mutate] = useMutation();
  const translate = useTranslate();
  const redirect = useRedirect();
  const unselectAll = useUnselectAll();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const dispatch = useDispatch();
  const notify = useNotify();

  const [open, setOpen] = useState(false);
  const [jobOptions, setJobOptions] = useState([]);
  const [existingPrintJobModels, setExistingPrintJobModels] = useState([]);
  const [currentPrintJobModels, setCurrentPrintJobModels] = useState([]);
  const [csvParsing, setCsvParsing] = useState(false);

  const isLoading = open && jobOptions.length === 0;
  const printJobId = get(props, "record.__id");
  const url = window.location;

  const getModelsFromSelectedJobId = async id => {
    const { data: response } = await dataProvider(GET_ONE, resources.JOBS, {
      id,
    });
    addNewModelsFromJob(response);
  };

  const addNewModelsFromJob = async job => {
    if (job && job?.__id) {
      const response = await dataProvider(GET_LIST, resources.MODELS, {
        pagination: { perPage: 25 },
        sort: { field: "id", order: "desc" },
        filter: {
          "job.id": job?.__id,
        },
      });

      const newModels = response?.data
        ? response.data.reduce((array, it) => {
            const alreadyExists = currentPrintJobModels.some(({ rapidshopId }) => rapidshopId === it?.rapidshopId);
            return alreadyExists
              ? array
              : (array.push({
                  ...it,
                  id: null,
                  modelId: it?.id,
                  rapidshopId: it?.rapidshopId,
                  externalId: job?.externalId,
                  count: parseInt(it?.assignedCount),
                  note: it?.note,
                }),
                array);
          }, [])
        : [];
      setCurrentPrintJobModels(oldModels => [...oldModels, ...newModels]);
    }
  };

  const handleAutocompleteInputChange = async value => {
    if (value) {
      const response = await dataProvider(GET_LIST, resources.JOBS, {
        pagination: { perPage: 20 },
        sort: { field: "id", order: "desc" },
        filter: {
          externalId: value,
          "contractor.contractorType": ContractorTypes.INTERNAL.key,
          active: true,
          hasActiveModels: true,
        },
      });

      setJobOptions(response?.data);
    }
  };

  const handleNetfabbFileSelect = async event => {
    setCsvParsing(true);
    const fileMultipart = new FormData();
    fileMultipart.append("file", new Blob([event.target.files[0]], { type: "text/csv" }));
    const filePostResponse = await fetchService.fetch(
      "post",
      `${config.urls.BACKEND}/netfabb/parse`,
      "",
      fileMultipart
    );
    if (filePostResponse.status === 500) {
      notify(translate("printJobUpload500Error"), "error");
    } else {
      const filePost = await filePostResponse.json();

      if (isEmpty(filePost)) {
        notify(translate("printJobUploadResultEmpty"));
      } else {
        const models = filePost.map(({ model, job, count }) => ({
          id: null,
          modelId: model.id,
          rapidshopId: model.rapidshopId,
          externalId: job.externalId,
          count: count,
          note: model.note,
        }));

        setCurrentPrintJobModels(prevState => [...prevState, ...models]);
      }
    }
    setCsvParsing(false);
  };

  const onSuccess = data => {
    for (const item of currentPrintJobModels) {
      const existingItem = existingPrintJobModels.find(({ modelId }) => modelId === item.modelId);
      if (!existingItem) {
        mutate({
          type: "create",
          resource: resources.PRINT_JOB_MODELS,
          payload: {
            data: {
              printJob: `${config.urls.BACKEND}/v2/${resources.PRINT_JOBS}/${data.data.__id}`,
              model: `${config.urls.BACKEND}/v2/${resources.MODELS}/${item.modelId}`,
              count: item.count,
              note: item.note,
            },
          },
        });
      } else {
        if (item?.count !== existingItem?.count || item?.note !== existingItem?.note) {
          mutate({
            type: "update",
            resource: resources.PRINT_JOB_MODELS,
            payload: {
              id: item.id,
              data: {
                count: item.count,
                note: item.note,
              },
            },
          });
        }
      }
    }

    const itemsToDelete = existingPrintJobModels.filter(
      oldItem => !currentPrintJobModels.find(({ id }) => id === oldItem.id)
    );
    if (itemsToDelete.length > 0) {
      mutate({
        type: "deleteMany", // waiting for backend debug → is not working yet
        resource: resources.PRINT_JOB_MODELS,
        payload: { ids: itemsToDelete.map(({ id }) => id) },
      });
    }

    dispatch(checkPrintjobTimes(data.data.__id));

    unselectAll(resources.JOBS);
    redirect("/printJobs");
    refresh();
  };

  useEffect(() => {
    if (url.href.indexOf("selectedIds=") > 0) {
      let ids = url?.href?.split("selectedIds=")[1].split(",");
      for (const id of ids) {
        getModelsFromSelectedJobId(id);
      }
    }

    if (printJobId) {
      fetchPrintJobModelsByPrintJob(dataProvider, get(props, "record.printJobModels_S")).then(result => {
        setExistingPrintJobModels(result);
        // since js is copy by reference we would get the same object in both states
        // and there would be no differences in 'onSuccess'
        // this way we get an actual new instance with all new object instances
        setCurrentPrintJobModels(JSON.parse(JSON.stringify(result)));
      });
    }
  }, [printJobId]);

  return (
    <SimpleForm
      toolbar={
        <Toolbar {...props}>
          <SaveButton onSuccess={onSuccess} />
        </Toolbar>
      }
      initialValues={{ enabled: true }}
      className={classes.form}
      {...props}
    >
      {!printJobId && (
        <TextInput
          className={classes.printJobInput}
          source="status"
          helperText={false}
          defaultValue="NEW"
          disabled
          fullWidth
        />
      )}
      <Box className={classes.horizontalInputsContainer}>
        <ReferenceInput
          className={classes.printJobInput}
          reference={resources.PRINTERS}
          source="printer"
          label={translate(getKey("printer", resources.PRINT_JOBS))}
          validate={validateRequired}
        >
          <CustomFilteredSelectInput
            optionText="name"
            optionValue="id"
            helperText={false}
            filter={choices =>
              sortBy(
                filter(choices, itm => !["HP-A", "HP-B", "SLMA"].includes(itm?.name)),
                [
                  function(o) {
                    return o.name <= "Anderer Drucker";
                  },
                  "name",
                ],
                ["asc"]
              )
            }
          />
        </ReferenceInput>
        <ReferenceInput
          className={classes.printJobInput}
          reference={resources.BUILD_UNITS}
          source="buildUnit"
          allowEmpty
          label={translate(getKey("buildUnit", resources.PRINT_JOBS))}
        >
          <CustomFilteredSelectInput
            optionText="name"
            optionValue="id"
            helperText={false}
            filter={choices => filter(choices, itm => itm?.name.startsWith("BU"))}
          />
        </ReferenceInput>
        <ReferenceInput
          className={classes.printJobInput}
          reference={resources.BUILD_UNITS}
          source="ncu"
          allowEmpty
          label={translate(getKey("ncu", resources.PRINT_JOBS))}
        >
          <CustomFilteredSelectInput
            optionText="name"
            optionValue="id"
            helperText={false}
            filter={choices => filter(choices, itm => itm?.name.startsWith("NCU"))}
          />
        </ReferenceInput>
      </Box>
      <ReferenceInput
        className={classes.printJobInput}
        reference={resources.USERS}
        source="assignedTo"
        filter={{ authority: ["ROLE_ADMIN", "ROLE_SALES"] }}
        perPage={100}
        label={translate(getKey("assignedTo", resources.PRINT_JOBS))}
      >
        <SelectInput
          optionText="displayName"
          optionValue="id"
          helperText={false}
          initialValue={permissions && `/users/${permissions?.id}`}
        />
      </ReferenceInput>
      <Box className={classes.horizontalInputsContainer}>
        <NumberInput
          className={classes.printJobInput}
          type="number"
          min={0}
          max={100}
          step={0.01}
          source="density"
          helperText={false}
          label={translate(getKey("density", resources.PRINT_JOBS))}
        />
        <NumberInput
          className={classes.printJobInput}
          type="number"
          step={0.01}
          source="height"
          helperText={false}
          label={translate(getKey("height", resources.PRINT_JOBS))}
        />
      </Box>
      <CustomDateInputs classes={classes} />
      <CustomDurationInputs classes={classes} />
      <TextInput
        className={classes.printJobInput}
        label={translate(getKey("notes", resources.PRINT_JOBS))}
        source="notes"
        helperText={false}
      />
      <div className={classes.fileWrapper}>
        <Typography>{translate("printJobUploadLabel")}</Typography>
        <input
          accept=".csv"
          className={classes.inputFile}
          id="contained-button-file"
          type="file"
          onChange={e => handleNetfabbFileSelect(e)}
          disabled={csvParsing}
        />
        <label htmlFor="contained-button-file">
          <Button
            startIcon={csvParsing ? <CircularProgress size={20} /> : <UploadIcon />}
            variant="contained"
            color="primary"
            component="span"
            disabled={csvParsing}
          >
            {translate("upload.start")}
          </Button>
        </label>
      </div>
      <div className={classes.autoComplete}>
        <Autocomplete
          autoComplete
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
            setJobOptions([]);
          }}
          onChange={(event, newValue) => {
            addNewModelsFromJob(newValue);
          }}
          getOptionLabel={option => `${option.contractorName} (${option.externalId})`}
          options={jobOptions}
          loading={isLoading}
          loadingText={`${translate(getKey("enterJobIds", resources.PRINT_JOBS))}`}
          filterOptions={(jobOptions, state) => jobOptions}
          onInputChange={(event, value) => handleAutocompleteInputChange(value)}
          renderInput={params => <TextField {...params} label={translate("jobsNumberLabel")} variant="outlined" />}
        />
      </div>
      {currentPrintJobModels && (
        <PrintJobModelsTable
          currentPrintJobModels={currentPrintJobModels}
          setCurrentPrintJobModels={setCurrentPrintJobModels}
        />
      )}
    </SimpleForm>
  );
};

export default PrintJobForm;
