import React from "react";
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  regex,
  required,
  SimpleForm,
  TextInput,
  translate,
} from "react-admin";
import resources from "../../config/resources";
import MaterialiseMaterialMappingAside from "./MaterialiseMaterialMappingAside";

const internalMaterialValidation = required();
const uuidValidation = [
  required(),
  regex(
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/,
    () => "materialiseReferenceError"
  ),
];

const MaterialiseMaterialMappingsCreate = ({ translate, ...props }) => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Create {...props} aside={<MaterialiseMaterialMappingAside {...props} />}>
      <SimpleForm initialValues={{ enabled: true }} style={{ width: 600, maxWidth: "40vw" }} redirect="list">
        <ReferenceInput
          source="internalMaterial"
          reference={resources.MATERIALS}
          perPage={1000}
          filterToQuery={searchText => ({ autocomplete: searchText })}
          validate={internalMaterialValidation}
          fullWidth
        >
          <AutocompleteInput
            optionText={record => {
              let text = record?.name;
              if (text) {
                if (record?.color) {
                  text = `${text} (${record.color})`;
                }
                if (record?.layerThickness) {
                  text = `${text} ${record.layerThickness}`;
                }
                return text;
              } else return "";
            }}
            optionValue="id"
          />
        </ReferenceInput>
        <TextInput source="materialiseTechnology" fullWidth validate={uuidValidation} />
        <TextInput source="materialiseMaterial" fullWidth validate={uuidValidation} />
        <TextInput source="materialiseFinishing" fullWidth validate={uuidValidation} />
        <TextInput source="materialiseColor" fullWidth validate={uuidValidation} />
      </SimpleForm>
    </Create>
  </div>
);

export default translate(MaterialiseMaterialMappingsCreate);
